<template>
  <div>
    <NavigationBar
      titel="Details"
      showBackBtn
      backRoute="scheduling-events"
      :actions="actions"
      :tabs="tabs"
      :activeTab="activeTab"
    ></NavigationBar>
    <CustomDialog
      v-if="autoLimitEventAttendanceDialog.model"
      @close="toggleAutomateAttendanceRestrictionDialog()"
      title="Teilnahme auf Einteilung beschränken"
      text="Für die Veranstaltung wird die Teilnahmebeschränkung automatisch angepasst. Alle Personen mit positiven Rückmeldungen oder einer Vorab-Einteilung werden zur Teilnahme zugelassen. Übrige Rückmeldungen von anderen Personen werden ausgeblendet."
      :loading="autoLimitEventAttendanceDialog.loading"
      :btnAction="automateAttendanceRestrictionAction"
      :errorMessage="autoLimitEventAttendanceDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="eventDeletionDialog.model"
      @close="toggleEventDeletionDialog()"
      title="Veranstaltung löschen"
      text="Bist Du sicher, dass Du diese Veranstaltung löschen möchtest? Um eine Veranstaltung abzusagen, aktualisiere den Status der Veranstaltung."
      :loading="eventDeletionDialog.loading"
      :btnAction="deleteEventAction"
      :errorMessage="eventDeletionDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="resetResponsesDialog.model"
      @close="toggleResetResponsesDialog()"
      title="Rückmeldungen zurücksetzen"
      text="Bist Du sicher, dass Du alle bereits abgegebenen Rückmeldungen zurücksetzen möchtest? Sie werden gelöscht und können nicht wiederhergestellt werden. Automatisierte Rückmeldungen sind davon nicht betroffen."
      :loading="resetResponsesDialog.loading"
      :btnAction="resetResponsesAction"
      :errorMessage="resetResponsesDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="recountResponsesDialog.model"
      @close="toggleRecountResponsesDialog()"
      title="Rückmeldevorschau neu berechnen"
      text="Bist Du sicher, dass Du die Rückmeldevorschau neu berechnen möchtest?"
      :loading="recountResponsesDialog.loading"
      :btnAction="recountResponsesAction"
      :errorMessage="recountResponsesDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="lockResponsesDialog.model"
      @close="toggleLockResponsesDialog()"
      :title="lockResponseDialogTitle"
      :text="lockResponseDialogText"
      :loading="lockResponsesDialog.loading"
      :btnAction="lockResponsesAction"
      :errorMessage="lockResponsesDialog.errorMessage"
      btnColor="error"
      width="s"
    ></CustomDialog>
    <CustomDialog
      v-if="statusDialog.model"
      @close="toggleEventStatusDialog()"
      title="Veranstaltungsstatus aktualisieren"
      width="s"
    >
      <template v-slot:content>
        <br />
        <v-form v-model="formIsValid" @submit.prevent="updateEventStatus()">
          <v-select
            v-model="eventData.status"
            :items="statusValues"
            item-text="status"
            :rules="[rules.required]"
            label="Status auswählen"
            outlined
          >
            <template v-slot:item="data">
              <v-list-item two-line>
                <v-list-item-action>
                  <v-icon :color="data.item.color">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.status }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="data">
              <v-list-item @click="toggle">
                <v-list-item-action>
                  <v-icon :color="data.item.color">
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.status }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
          <v-select
            v-if="eventData.status === 'canceled'"
            v-model="eventData.statusAnalytics.id"
            :items="reasonItems"
            label="Begründung für Status auswählen"
            :disabled="eventData.status !== 'canceled'"
            :rules="[rules.required]"
            outlined
            hint="Wird für statistische Auswertung erfasst"
            persistent-hint
          >
          </v-select>
          <v-text-field
            v-if="eventData.status === 'canceled'"
            v-model="eventData.statusAnalytics.reason"
            :disabled="eventData.status !== 'canceled'"
            label="Ergänzende Information zur Begründung (Optional)"
            hint="Wird für statistische Auswertung erfasst"
            persistent-hint
            outlined
          ></v-text-field>
          <v-textarea
            v-model="eventData.internalNote"
            counter
            clearable
            rows="3"
            no-resize
            auto-grow
            label="Interne Notiz"
            outlined
          ></v-textarea>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn text @click="toggleEventStatusDialog()">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="success"
          :disabled="!formIsValid"
          @click="updateEventStatus()"
        >
          <v-icon left>mdi-content-save</v-icon>Speichern</v-btn
        ></template
      >
    </CustomDialog>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      >{{ helpers.snackbar.message }}
    </v-snackbar>
    <v-container v-if="eventCanBeDocumented" class="mb-0">
      <v-alert
        elevation="2"
        colored-border
        border="left"
        color="primary"
        class="mb-0"
      >
        <v-row align="center">
          <v-col class="grow subtitle-2">
            Für diese Veranstaltung kann die Anwesenheit in einem Dienstbericht
            erfasst werden.</v-col
          >
          <v-col class="shrink">
            <v-btn
              color="primary"
              depressed
              :to="{
                name: 'duty-management-reports-new',
                params: {
                  organizationId: $route.params.organizationId,
                },
                query: {
                  dutyTypeId: eventData.type.dutyTypeId || null,
                  eventId: eventData.meta.id,
                },
              }"
            >
              Anwesenheit erfassen</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </v-container>
    <router-view :event="event" :eventType="eventData.type" />
  </div>
</template>

<script>
import { db, auth, functions, FieldValue } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import {
  SCHEDULING_EVENTS_update,
  SCHEDULING_EVENTS_delete,
  SCHEDULING_EVENTS_RESPONSES_write,
  DUTY_MANAGEMENT_REPORTS_create,
} from "@/data/permission-types.js";
import { SCHEDULING } from "@/store/modules.js";
import {
  DELETE_EVENT,
  // RESET_RESPONSES,
  UPDATE_EVENT_META,
} from "@/store/action-types.js";

export default {
  name: "scheduling-event-details",
  components: {
    NavigationBar,
    CustomDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      personsData: [],
      eventData: {
        title: "",
        description: "",
        type: {
          id: "",
          title: "",
          color: "",
          description: "",
        },
        location: {
          name: "",
        },
        duration: {
          start: {
            timestamp: "",
            timestampToDate: "",
            isOpen: false,
          },
          end: {
            timestamp: "",
            timestampToDate: "",
            isOpen: false,
          },
        },
        details: [],
        config: {
          organizer: {
            isAppointed: false,
            hasAdditionalPermissions: false,
            description: "",
            personIds: [],
            personData: [],
          },
          attendance: {
            isMandatory: false,
            isLimited: false,
            minAttendees: null,
            maxAttendees: null,
            allowedAttendeesIds: [],
            allowedPersons: [],
            allowedUserGroups: [],
          },
          responses: {
            areBinding: false,
            areLocked: false,
            deadline: {
              isCustom: false,
              timestamp: null,
            },
          },
        },
        count: {
          totalResponses: 0,
          totalConfirmations: 0,
          totalCancellations: 0,
          totalIfNecessary: 0,
          totalGuests: 0,
        },
        status: "no data", // 'draft', 'planned', 'live', 'finished', 'canceled',
        statusAnalytics: {
          id: null,
          reason: null,
        },
        internalNote: "",
        meta: {
          id: "",
          createdAt: "",
          createdBy: "",
          lastUpdatedBy: "",
          lastUpdatedAt: "",
        },
      },

      reasonItems: [
        { text: "Zu wenig Teilnehmende", value: "c1" },
        { text: "Genug Teilnehmende, fehlende Qualifikationen", value: "c2" },
        { text: "Terminkollisionen", value: "c3" },
        { text: "Schlechte Wetterbedingungen", value: "c4" },
        { text: "Fehlende Technik/Geräte/Fahrzeuge", value: "c5" },
        {
          text: "Ruhebedingt (bspw. aufgrund vorheriger Veranstaltung)",
          value: "c6",
        },
        { text: "Sonstiges", value: "c0" },
      ],

      activeTab: `general`,
      tabs: [
        { id: 1, name: "Allgemein", route: `general` },
        { id: 2, name: "Rückmeldungen", route: `responses` },
      ],
      autoLimitEventAttendanceDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      eventDeletionDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      resetResponsesDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      lockResponsesDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      statusDialog: {
        model: false,
      },
      recountResponsesDialog: {
        model: false,
        loading: false,
        errorMessage: "",
      },
      formIsValid: false,
      helpers: {
        isLoading: true,
        snackbar: {
          isVisible: false,
          message: "",
        },
      },
    };
  },
  computed: {
    event() {
      return this.eventData;
    },
    dutyReportingFeatureIsAvailable() {
      return this.$store.getters["organization/checkFeatureAvailabilityById"](
        "enableDutyReporting"
      );
    },
    dutyReportingIsEnabled() {
      return (
        this.$store.getters["organization/checkExtensionAvailabilityById"](
          "dutyManagement.reports"
        ) &&
        this.$store.getters["organization/checkPermissionByID"](
          `${DUTY_MANAGEMENT_REPORTS_create}`
        )
      );
    },
    eventCanBeDocumented() {
      if (
        !this.helpers.isLoading &&
        this.event &&
        this.event.duration.start.timestamp.toDate() <=
          new Date(new Date().getTime() + 2 * 60 * 60 * 1000) &&
        (this.event.status === "planned" || this.event.status === "finished") &&
        this.dutyReportingIsEnabled &&
        this.dutyReportingFeatureIsAvailable
      ) {
        return true;
      } else {
        return false;
      }
    },
    actions() {
      let isEventOrganizerWithPermission = false;

      if (
        this.event.config.organizer.isAppointed &&
        this.event.config.organizer.hasAdditionalPermissions &&
        this.event.config.organizer.personIds.includes(auth.currentUser.uid)
      ) {
        isEventOrganizerWithPermission = true;
      } else {
        isEventOrganizerWithPermission = false;
      }

      return [
        {
          title: "Bearbeiten",
          permission: `${SCHEDULING_EVENTS_update}`,
          customPermission: isEventOrganizerWithPermission,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "scheduling-event-edit",
              params: {
                organizationId: this.$route.params.organizationId,
                itemId: this.$route.params.itemId,
              },
            });
          },
        },

        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "Status aktualisieren",
              permission: `${SCHEDULING_EVENTS_update}`,
              customPermission: isEventOrganizerWithPermission,
              icon: "mdi-list-status",
              actionStyle: "textBtn",
              function: this.toggleEventStatusDialog,
            },
            {
              title: "Löschen",
              permission: `${SCHEDULING_EVENTS_delete}`,
              icon: "mdi-delete",
              actionStyle: "textBtn",
              function: this.toggleEventDeletionDialog,
            },
            { divider: true },
            {
              title: "Teilnahme auf Einteilung beschränken",
              permission: `${SCHEDULING_EVENTS_update}`,
              customPermission: isEventOrganizerWithPermission,
              icon: "mdi-account-multiple-check",
              actionStyle: "textBtn",
              function: this.toggleAutomateAttendanceRestrictionDialog,
            },
            {
              title: "Rückmeldevorschau neu berechnen",
              permission: `${SCHEDULING_EVENTS_update}`,
              customPermission: isEventOrganizerWithPermission,
              icon: "mdi-counter",
              actionStyle: "textBtn",
              function: this.toggleRecountResponsesDialog,
            },
            {
              title: "Rückmeldungen sperren/öffnen",
              permission: `${SCHEDULING_EVENTS_RESPONSES_write}`,
              customPermission: isEventOrganizerWithPermission,
              icon: "mdi-lock",
              disabled: false,
              function: this.toggleLockResponsesDialog,
            },
            {
              title: "Rückmeldungen zurücksetzen",
              permission: `${SCHEDULING_EVENTS_RESPONSES_write}`,
              customPermission: isEventOrganizerWithPermission,
              icon: "mdi-restore",
              disabled: false,
              function: this.toggleResetResponsesDialog,
            },
            {
              title: "Einladung per E-Mail senden",
              permission: `${SCHEDULING_EVENTS_update}`,
              icon: "mdi-email",
              disabled: true,
              function: this.createItem,
            },
            {
              title: "Veranstaltungsbericht exportieren ",
              permission: `${SCHEDULING_EVENTS_update}`,
              icon: "mdi-file-export",
              disabled: true,
              function: this.createFromCSV,
            },
          ],
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ];
    },
    statusValues() {
      var values = [
        {
          icon: "mdi-calendar-check",
          value: "planned",
          status: "Geplant",
          description:
            "Die Veranstaltung erscheint in der Liste der bevorstehenden Veranstaltungen.",
          color: "info",
        },
        {
          icon: "mdi-calendar-alert",
          value: "postponed",
          status: "Verschoben",
          description: "Die Veranstaltung wird zunächst im Archiv abgelegt.",
          color: "warning",
        },
        {
          icon: "mdi-calendar-remove",
          value: "canceled",
          status: "Abgesagt",
          description: "Die Veranstaltung wird im Archiv abgelegt.",
          color: "error",
        },
      ];
      if (this.checkIfSupport) {
        values.push({
          icon: "mdi-calendar-check",
          value: "finished",
          status: "Abgeschlossen",
          description: "Die Veranstaltung wird  im Archiv abgelegt.",
          color: "success",
        });
        return values;
      } else {
        return values;
      }
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    lockResponseDialogTitle() {
      if (this.eventData.config.responses.areLocked) {
        return "Rückmeldungen öffnen";
      } else {
        return "Rückmeldungen sperren";
      }
    },
    lockResponseDialogText() {
      if (this.eventData.config.responses.areLocked) {
        return "Bist Du sicher, dass Du alle bereits abgegebenen Rückmeldungen öffnen möchtest? Änderungen können wieder vorgenommen und neue Rückmeldungen abgegeben werden.";
      } else {
        return "Bist Du sicher, dass Du alle bereits abgegebenen Rückmeldungen sperren möchtest? Änderungen können nicht mehr vorgenommen und neue Rückmeldungen nicht mehr abgegeben werden.";
      }
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.eventData = doc.data();
            this.eventData.duration.start.timestampToDate = doc
              .data()
              .duration.start.timestamp.toDate();
            this.eventData.duration.end.timestampToDate = doc
              .data()
              .duration.end.timestamp.toDate();
            this.eventData.userIsInvited = this.checkIfUserIsInvited(
              auth.currentUser.uid,
              this.$store.state.organization.membership.group.id,
              doc.data().config.attendance
            );
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
          this.helpers.isLoading = false;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    checkIfUserIsInvited(uid, userGroupId, attendance) {
      if (attendance.isLimited) {
        return (
          attendance.allowedAttendeesIds.includes(uid) ||
          attendance.allowedAttendeesIds.includes(userGroupId) ||
          attendance.allowedAttendeesIds.includes("all")
        );
      } else {
        return true;
      }
    },
    automateAttendanceRestrictionAction() {
      this.autoLimitEventAttendanceDialog.loading = true;

      const callFunction = functions.httpsCallable(
        "scheduling-autoLimitEventAttendance"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        eventId: this.$route.params.itemId,
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.autoLimitEventAttendanceDialog.model = false;
            this.autoLimitEventAttendanceDialog.loading = false;
            this.helpers.snackbar.isVisible = true;
            this.helpers.snackbar.message =
              "Teilnahme auf Einteilung beschränkt.";
          } else {
            this.autoLimitEventAttendanceDialog.errorMessage =
              result.data.error.message;
            this.autoLimitEventAttendanceDialog.loading = false;
          }
        })
        .catch((error) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(error);
          // this.errorMsg = err.message;
          this.autoLimitEventAttendanceDialog.errorMessage = error.message;
          this.autoLimitEventAttendanceDialog.loading = false;
        });
    },
    resetResponsesAction() {
      this.resetResponsesDialog.loading = true;
      const callFunction = functions.httpsCallable("scheduling-resetResponses");
      return callFunction({
        organizationId: this.$route.params.organizationId,
        eventId: this.$route.params.itemId,
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.resetResponsesDialog.model = false;
            this.resetResponsesDialog.loading = false;
            this.helpers.snackbar.isVisible = true;
            this.helpers.snackbar.message = "Rückmeldungen zurückgesetzt.";
          } else {
            this.resetResponsesDialog.errorMessage = result.data.error.message;
            this.resetResponsesDialog.loading = false;
          }
        })
        .catch((error) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(error);
          // this.errorMsg = err.message;
          this.resetResponsesDialog.errorMessage = error.message;
          this.resetResponsesDialog.loading = false;
        });
    },
    recountResponsesAction() {
      this.recountResponsesDialog.loading = true;
      const callFunction = functions.httpsCallable(
        "scheduling-recountEventResponses"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        eventId: this.$route.params.itemId,
      })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.recountResponsesDialog.model = false;
            this.recountResponsesDialog.loading = false;
            this.helpers.snackbar.isVisible = true;
            this.helpers.snackbar.message = "Rückmeldevorschau neu berechnet.";
          } else {
            this.recountResponsesDialog.errorMessage =
              result.data.error.message;
            this.resetResponsesDialog.loading = false;
          }
        })
        .catch((error) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(error);
          // this.errorMsg = err.message;
          this.resetResponsesDialog.errorMessage = error.message;
          this.resetResponsesDialog.loading = false;
        });
    },
    lockResponsesAction() {
      this.lockResponsesDialog.loading = true;
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events")
        .doc(this.$route.params.itemId)
        .update({
          "config.responses.areLocked":
            !this.eventData.config.responses.areLocked,
          "meta.lastUpdatedAt": FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": auth.currentUser.uid,
        })
        .then(() => {
          this.toggleLockResponsesDialog();
          this.eventData.config.responses.areLocked =
            !this.eventData.config.responses.areLocked;
          if (this.eventData.config.responses.areLocked) {
            this.helpers.snackbar.message = "Rückmeldungen gesperrt";
          } else {
            this.helpers.snackbar.message = "Rückmeldungen geöffnet";
          }
          this.lockResponsesDialog.loading = false;
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => console.log(error));
    },
    deleteEventAction() {
      // alert("Fehler: Kontaktieren Sie den Support.");
      this.eventDeletionDialog.loading = true;
      this.$store
        .dispatch(`${SCHEDULING}${DELETE_EVENT}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        })
        .then((result) => {
          if (result.data.success) {
            // this.showSuccess = true;
            this.eventDeletionDialog.model = false;
            this.eventDeletionDialog.loading = false;
            // this.snackbar = true;
            this.$router.push({
              name: "scheduling-events",
              params: { organizationId: this.$route.params.organizationId },
            });
          } else {
            // this.errorMsg = "Fehler";
            this.eventDeletionDialog.loading = false;
          }
        })
        .catch((err) => {
          // handle error
          // console.error(err.errorMsg);
          console.error(err);
          this.eventDeletionDialog.errorMessage = err.message;
          this.eventDeletionDialog.loading = false;
        });
    },
    updateEventStatus() {
      if (this.formIsValid) {
        this.helpers.isLoading = true;
        if (this.eventData.status !== "canceled") {
          this.eventData.statusAnalytics.id = null;
          this.eventData.statusAnalytics.reason === null;
        }
        this.$store
          .dispatch(`${SCHEDULING}${UPDATE_EVENT_META}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,

            status: this.eventData.status,
            statusAnalytics: {
              id: this.eventData.statusAnalytics.id,
              reason: this.eventData.statusAnalytics.reason,
            },
            internalNote: this.eventData.internalNote,
          })
          .then(() => {
            this.toggleEventStatusDialog();
            this.helpers.isLoading = false;
            this.helpers.snackbar.message =
              "Neuer Veranstaltungsstatus gespeichert.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.snackbar.message = error;
            this.helpers.snackbar.isVisible = true;
          });
      } else {
        alert("Prüfe Deine Eingabe und versuchen es erneut.");
      }
    },
    toggleAutomateAttendanceRestrictionDialog() {
      this.autoLimitEventAttendanceDialog.model =
        !this.autoLimitEventAttendanceDialog.model;
    },
    toggleEventDeletionDialog() {
      this.eventDeletionDialog.model = !this.eventDeletionDialog.model;
    },
    toggleResetResponsesDialog() {
      this.resetResponsesDialog.model = !this.resetResponsesDialog.model;
      this.resetResponsesDialog.errorMessage = "";
    },
    toggleLockResponsesDialog() {
      this.lockResponsesDialog.model = !this.lockResponsesDialog.model;
    },
    toggleEventStatusDialog() {
      this.statusDialog.model = !this.statusDialog.model;
    },
    toggleRecountResponsesDialog() {
      this.recountResponsesDialog.model = !this.recountResponsesDialog.model;
      this.recountResponsesDialog.errorMessage = "";
    },
  },
};
</script>

<style></style>
